<template>
  <div class="mt-20 w-full mx-auto text-gray-900 px-4">
    <h2 class="text-gray-800 text-sm">
      Change your Master Key Password below.
    </h2>

    <div class="flex items-center gap-8 py-4 text-black">
      Enter Password
      <input
        v-model="newKey.key"
        class="w-1/4 bg-gray-200 py-2 px-4 rounded-lg outline-none"
        type="password"
      />
    </div>

    <div class="flex items-center gap-3 py-1 text-black">
      Confirm Password
      <input
        v-model="newKey.confirm_key"
        class="w-1/4 bg-gray-200 py-2 px-4 rounded-lg outline-none"
        type="password"
      />
    </div>

    <button
      :class="{ 'cursor-not-allowed': !canSave }"
      :disabled="!canSave"
      @click="saveMasterKey"
      class="
        mt-8
        text-black
        font-semibold
        text-sm
        rounded-lg
        px-12
        py-2
        bg-gray-200
        hover:bg-gray-300
        uppercase
      "
    >
      Submit
    </button>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import alertTypes from "@/utils/alert-types";
export default {
  name: "Master Key",
  data() {
    return {
      newKey: {
        key: "",
        confirm_key: "",
      },
    };
  },

  methods: {
    async saveMasterKey() {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/settings/masterkey`,
          this.newKey
        );

        if (res.status === 200) {
          this.$store.dispatch("addAlert", {
            ...alertTypes.success,
            alert: "master key changed",
          });
          this.newKey = {
            key: "",
            confirm_key: "",
          };
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  computed: {
    canSave() {
      return (
        this.newKey.key &&
        this.newKey.confirm_key &&
        this.newKey.key === this.newKey.confirm_key
      );
    },
  },
  components: {},
};
</script>

<style>
</style>